<template>
  <div class="statistics">
    <div class="loader" :class="{ hiddenload }">
      <div class="loader__indicator" :class="{ loading: !errorload }">
        <div class="one"></div>
        <div class="two"></div>
        <div class="three"></div>
      </div>
      <div class="loader__error" :class="{ error: errorload }">
	Failed to set up connection.	
      </div>
    </div>
    <div class="table-container">
      <Table
        :loaded="loaded"
        :unselected="unselected"
        @rowselected="rowSelected"
      />
    </div>
    <div class="card-container">
      <Card
        :selected="selected"
        :class="{ hidden: !selected }"
        @unselect="unselected = {}"
      />
    </div>
    <div class="card-container-modal" :class="{ selected }" ref="card-modal">
      <div class="card-container-modal__wrapper">
        <Card :selected="selected" @unselect="unselected = {}" />
      </div>
    </div>
  </div>
</template>

<script>
import Table from "../components/Table/Table";
import Card from "../components/Card/Card";
import { ActionConstructor } from "@egalteam/framework/compile/index";
import Centrifuge from "centrifuge";

export default {
  name: "Statistics",
  components: { Card, Table },
  data() {
    return {
      loaded: [],
      selected: null,
      unselected: null,
      actionConstructor: null,
      hiddenload: false,
      errorload: false,
    };
  },
  methods: {
    rowSelected(row) {
      this.selected = row;
    },
    getItems() {
      setTimeout(() => {
        if (this.hiddenload === false) {
          this.errorload = true;
        }
      }, 3000);
      this.actionConstructor
        .getItems("core", "Device")
        .setPagination(10000)
        .order([
          ["condition", "desc"],
          ["id", "asc"],
        ])
        .call()
        .then((data) => {
          this.loaded = data.items.map((item) => {
            let online = item.condition;
            let notifications = item["alert_notification"];
            let charge = item.battery_charge;
            let id = item.id;
            let pulse = item.pulse || "-";
            let spO2 = item.saturation || "-";
            let temp = item.temperature || "-";
            let steps = item.count_steps || "-";
            let vsr = item.vsr || "-";
            let drops = item.count_drops || "-";
            let hits = item.count_hits || "-";
            let longitude = item.longitude;
            let latitude = item.latitude;
            let year = item.year || "-";
            let month = item.month || "-";
            let day = item.day || "-";
            let hour = item.hour || "-";
            let minute = item.minute || "-";
            let second = item.second || "-";
            return {
              online,
              notifications,
              charge,
              id,
              pulse,
              spO2,
              steps,
              temp,
              vsr,
              drops,
              hits,
              longitude,
              latitude,
              year,
              month,
              day,
              hour,
              minute,
              second,
            };
          });

          this.centrifuge.connect();

          setTimeout(() => {
            this.hiddenload = true;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.errorload = true;
          }, 1000);
        });
    },
  },
  mounted() {
    try {
      this.actionConstructor = new ActionConstructor(
        process.env.VUE_APP_BASE_URL
      );
    } catch (e) {
      setTimeout(() => {
        this.errorload = true;
      }, 1000);
    }
    this.getItems();
    this.centrifuge = new Centrifuge(
      "ws://portal.gearex.co.uk/connection/websocket"
    );

    this.centrifuge.subscribe("core", () => {
      this.getItems();
    });
  },
};
</script>

<style scoped lang="scss">
.statistics {
  display: flex;

  > * {
    height: 100vh;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
  }

  .card-container-modal {
    display: none;
  }

  @media (max-width: 1249px) {
    .table-container {
      flex-grow: 1;
    }
    .card-container {
      flex-basis: 30%;
      flex-grow: 0.7;
      min-width: 430px;
    }
  }

  @media (max-width: 750px) {
    .card-container {
      display: none;
    }

    .card-container-modal {
      display: block;
      background-color: white;
      height: 100vh;
      width: 100vw;
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      transform: translateX(100%);
      transition: 0.3s;

      &.selected {
        transform: translateX(0%);
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .card-container {
    background: #ddd;
    padding: 60px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #555 transparent;
    scrollbar-width: thin;
    border-radius: 91px;
    flex-shrink: 1;

    &:empty {
      background: #ddd url("../assets/imgs/bgs/thisone.png") no-repeat center;
      background-size: #{"max(20vw, 300px)"} auto;
    }

    > * {
      opacity: 1;
      transition: opacity 0.2s;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #202020;
    }
  }

  .hidden {
    opacity: 0;
  }

  .loader {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #202020;
    z-index: 10;
    top: 100%;
    transform: translateY(-100%);
    transition: transform 0.2s ease-out;

    &.hiddenload {
      transform: translateY(0);
    }

    &__indicator {
      top: 50%;
      left: 50%;
      display: flex;
      transition: 0.3s;

      div {
        transition: 0.3s;
        width: 30px;
        height: 90px;
        background: #fff;

        &.one {
          transform-origin: center;
          transform: translateX(100%) rotate(45deg);
        }
        &.two {
          background: #202020;
        }
        &.three {
          transform-origin: center;
          transform: translateX(-100%) rotate(-45deg);
        }
      }

      &.loading {
        div {
          margin: 10px;
          width: 50px;
          height: 50px;
          background-color: #fff;
          border-radius: 50%;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
          transform: translateX(0);

          &.one {
            animation-name: load-one;
          }

          @keyframes load-one {
            30% {
              transform: translateY(-50%);
            }
          }

          &.two {
            animation-name: load-two;
          }

          @keyframes load-two {
            50% {
              transform: translateY(-50%);
            }
          }

          &.three {
            animation-name: load-three;
          }

          @keyframes load-three {
            70% {
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    &__error {
      color: #fff;
      font-size: 18px;
      margin-top: 50px;
      transition: 0.3s;
      opacity: 0;

      &.error {
        opacity: 1;
      }
    }
  }
}
</style>
