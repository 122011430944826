<template>
  <div class="scroll-container">
    <div class="table">
      <div class="hideline header">Online</div>
      <div class="hideline header">ID</div>
      <div class="hideline header">
        <svg width="20px" height="20px">
          <image
            :xlink:href="require('../../assets/imgs/icons/pulse.svg')"
          ></image></svg
        >Pulse
      </div>
      <div class="hideline header">
        <svg width="20px" height="20px">
          <image
            :xlink:href="require('../../assets/imgs/icons/spo2.svg')"
          ></image></svg
        >SpO2
      </div>
      <div class="hideline header">
        <svg width="20px" height="20px">
          <image
            :xlink:href="require('../../assets/imgs/icons/temp.svg')"
          ></image></svg
        >t,C
      </div>
      <div class="hideline header">
        <svg width="20px" height="20px">
          <image
            :xlink:href="require('../../assets/imgs/icons/steps.svg')"
          ></image></svg
        >Steps
      </div>
      <div class="hideline header">
        <svg width="20px" height="20px">
          <image
            :xlink:href="require('../../assets/imgs/icons/notifications.svg')"
          ></image></svg
        >Notifications
      </div>
      <template v-for="(row, n) in sortedRows">
        <div
          class="online-indicator"
          :class="{
            online: row.online,
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          :key="n + 'online'"
          @click="selectRow(row)"
        >
          <span>{{ row.online ? "Active" : "Not active" }}</span>
        </div>
        <div
          :key="n + 'id'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          @click="selectRow(row)"
        >
          <span>ID:</span>
          {{ row.id }}
        </div>
        <div
          :key="n + 'pulse'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          @click="selectRow(row)"
        >
          <span>Pulse</span>
          <span>{{ row.pulse }} pm</span>
        </div>
        <div
          :key="n + 'spo2'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          @click="selectRow(row)"
        >
          <span>SpO2</span>
          <span>{{ row.spO2 }} %</span>
        </div>
        <div
          :key="n + 'temp'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          @click="selectRow(row)"
        >
          <span>Temperature</span>
          <span>{{ row.temp }} C</span>
        </div>
        <div
          :key="n + 'steps'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          v-on:click="selectRow(row)"
        >
          <span>Steps</span>
          <span>{{ row.steps }}</span>
        </div>
        <div
          :key="n + 'notifications'"
          :class="{
            hideline: n === 0 || sortedRows[n - 1].rowIsSelected,
            selected: row.rowIsSelected,
          }"
          @click="selectRow(row)"
        >
          <span>Notifications</span>
          <span>{{ row.notifications ? "Alarm!" : "-" }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      sortState: {
        type: "",
        direction: "normal",
      },
      sortedRows: [],
      selected: {},
      selectedId: -1,
    };
  },
  props: ["loaded", "unselected"],
  methods: {
    selectRow(row) {
      let wasSelected = row.rowIsSelected;
      this.selectedId = row.id;
      this.sortedRows.forEach((row) => {
        row.rowIsSelected = undefined;
      });

      row.rowIsSelected = !wasSelected;

      if (row.rowIsSelected) {
        this.$emit("rowselected", row);
        this.selected = row;
      } else {
        this.selected = null;
        this.$emit("rowselected", null);
      }
      this.sortedRows = JSON.parse(JSON.stringify(this.sortedRows));
    },
  },
  watch: {
    unselected() {
      this.selectRow(this.selected);
      this.selectedId = -1;
    },
    loaded() {
      this.sortedRows = JSON.parse(JSON.stringify(this.loaded));

      if (this.selectedId !== -1) {
        let selectedRow = this.sortedRows.find((el) => {
          return el.id === this.selectedId;
        });
        this.selectRow(selectedRow);
      }
    },
  },
};
</script>

<style lang="scss">
.table {
  color: #fff;
  background-color: #202020;
  font-size: 14px;
  line-height: 16px;
  display: grid;
  grid-template: "a";
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: 0.3s;
    padding: 15px calc(20px + 10%);

    &.header {
      display: none;
    }

    svg {
      margin-right: 10px;
    }

    &:nth-child(7n + 1) {
      padding-top: 30px;

      &.selected {
        border-radius: 20px 20px 0 0;
      }

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #fff;
        left: 0;
        top: 0;
      }
    }

    &:nth-last-child(7n + 1) {
      padding-bottom: 30px;

      &.selected {
        border-radius: 0 0 20px 20px;
      }
    }

    &.hideline:after {
      display: none;
    }

    &.selected {
      color: #000;
      background: #fff;

      &:after {
        display: none;
      }
    }

    &.online-indicator {
      position: relative;
      justify-content: center;

      &::before {
        position: relative;
        content: "";
        background-color: #ff0000;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        border-radius: 6px;
      }

      &.online::before {
        background-color: #52ff00;
      }
    }
  }

  @media (min-width: 1250px) {
    max-width: unset;
    grid-template: "a a a a a a a";

    div {
      padding: 21px 3.12%;
      justify-content: center;

      &.header {
        display: flex;
      }

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #fff;
        left: 0;
        top: 0;
      }

      &:nth-child(7n + 1) {
        padding: 0 0 0 1.56%;

        &.selected {
          border-radius: 20px 0 0 20px;
        }
      }

      &:nth-last-child(7n + 1) {
        padding: 0 3.12% 0 0;

        &.selected {
          border-radius: 0 20px 20px 0;
        }
      }

      &.online-indicator {
        position: relative;

        &::before {
          position: absolute;
          margin-right: 0px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span:first-child {
        display: none;
      }
    }
  }
}

.scroll-container {
  height: 80vh;
  padding: 0 10px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #555 transparent;
  scrollbar-width: thin;
  width: 100%;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent; /* or add it to the track */
  }

  &::-webkit-scrollbar-thumb {
    background: #555;
  }
}
</style>
