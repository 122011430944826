<template>
  <div
    class="modal-wrapper"
    style="display: none"
    @click.stop="close"
    ref="modal"
    :class="{ hidden: !isOpen }"
  >
    <div class="graphs" @click.stop :class="{ loading: loading }">
      <div class="graphs__btn-group">
        <button @click.stop="getItems('Hour')">1 hour</button>
        <button @click.stop="getItems('Day')">24 hours</button>
        <button @click.stop="getItems('Week')">1 week</button>
      </div>
      <div class="graphs__data">
        <div>
          <CardGraphLine
            :chartData="tempData"
            :options="options"
            :touch-to-render="renderTouch"
            :width="500"
            :height="120"
          />
        </div>
        <h4>Temperature</h4>
        <div>
          <CardGraphLine
            :chartData="pulseData"
            :options="options"
            :touch-to-render="renderTouch"
            :width="500"
            :height="120"
          />
        </div>
        <h4>Pulse</h4>
        <div>
          <CardGraphLine
            :chartData="saturationData"
            :options="options"
            :touch-to-render="renderTouch"
            :width="500"
            :height="120"
          />
        </div>
        <h4>Saturation (SpO2)</h4>
        <div>
          <CardGraphLine
            :chartData="vsrData"
            :options="options"
            :touch-to-render="renderTouch"
            :width="500"
            :height="120"
          />
        </div>
        <h4>Heart Rate Variability</h4>
      </div>
      <div class="graphs__close">
        <button @click.stop="close">CLOSE</button>
      </div>
      <div class="loader" :class="{ hidden: !loading }">
        <div class="btn-group">
          <button @click.stop="getItems('Hour')">1 hour</button>
          <button @click.stop="getItems('Day')">24 hours</button>
          <button @click.stop="getItems('Week')">1 week</button>
        </div>
        <div class="loader__indicator" :class="{ loading: !error }">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
        <div class="loader__error" :class="{ error }" @click.stop>
          {{ errMessage }}
        </div>
        <button @click.stop="close()">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
import CardGraphLine from "./CardGraphLine";
import { ActionConstructor } from "@egalteam/framework/compile/index";

export default {
  name: "CardGraphs",
  components: { CardGraphLine },
  props: ["touchToOpen", "deviceId"],
  data() {
    return {
      tempData: {
        labels: [],
        datasets: [
          {
            fill: false,
            label: "Temperature",
            borderColor: "#77b7cd",
            pointBackgroundColor: "#77b7cd",
            data: [],
          },
        ],
      },
      pulseData: {
        labels: [],
        datasets: [
          {
            fill: false,
            label: "Pulse",
            borderColor: "#77b7cd",
            pointBackgroundColor: "#77b7cd",
            data: [],
          },
        ],
      },
      saturationData: {
        labels: [],
        datasets: [
          {
            fill: false,
            label: "Saturation",
            borderColor: "#77b7cd",
            pointBackgroundColor: "#77b7cd",
            data: [],
          },
        ],
      },
      vsrData: {
        labels: [],
        datasets: [
          {
            fill: false,
            label: "HRV",
            borderColor: "#77b7cd",
            pointBackgroundColor: "#77b7cd",
            data: [],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 20,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                precision: 2,
                maxTicksLimit: 4,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              ticks: {
                maxTicksLimit: 5,
              },
            },
          ],
        },
      },
      isOpen: false,
      renderTouch: {},
      actionConstructor: null,
      error: false,
      errMessage: "",
      loading: true,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    getItems(name = "Day") {
      this.loading = true;
      this.error = false;

      let baseObject = {
        labels: [],
        datasets: [
          {
            fill: false,
            label: "",
            borderColor: "#77b7cd",
            pointBackgroundColor: "#77b7cd",
            data: [],
          },
        ],
      };

      this.tempData = JSON.parse(JSON.stringify(baseObject));
      this.tempData.datasets[0].label = "Temperature";

      this.pulseData = JSON.parse(JSON.stringify(baseObject));
      this.pulseData.datasets[0].label = "Pulse";

      this.saturationData = JSON.parse(JSON.stringify(baseObject));
      this.saturationData.datasets[0].label = "Saturation";

      this.vsrData = JSON.parse(JSON.stringify(baseObject));
      this.vsrData.datasets[0].label = "HRV";

      let filter = [[["device_id", "eq", this.deviceId]]];

      this.actionConstructor
        .getItems("core", `IndicatorsDevices${name}View`)
        .filter(filter)
        .setPagination(10000)
        .call()
        .then((data) => {
          if (data.items.length > 2) {
            data.items.forEach((el) => {
              let { pulse, saturation, temperature, vsr, time } = el;
              let date = new Date(time);
              let formatedDate =
                name === "Week"
                  ? date.toLocaleDateString()
                  : `${date.getHours()}:${date.getMinutes()}`;
              this.tempData.labels.push(formatedDate);
              this.tempData.datasets[0].data.push(temperature);

              this.pulseData.labels.push(formatedDate);
              this.pulseData.datasets[0].data.push(pulse);

              this.saturationData.labels.push(formatedDate);
              this.saturationData.datasets[0].data.push(saturation);

              this.vsrData.labels.push(formatedDate);
              this.vsrData.datasets[0].data.push(vsr);
            });

            this.renderTouch = {};

            setTimeout(() => {
              this.loading = false;
            }, 500);
          } else {
            throw new Error("Not enough data. Make sure this GearEx is active and try again later.");
          }
        })
        .catch((err) => {
          this.error = true;
          this.errMessage =
            err.message || "Failed to get data. Try again later.";
        });
    },
  },
  mounted() {
    this.actionConstructor = new ActionConstructor(
      process.env.VUE_APP_BASE_URL
    );

    let modal = this.$refs.modal;
    modal.addEventListener("transitionend", () => {
      if (!this.isOpen) {
        modal.style.display = "none";
      }
    });
  },
  watch: {
    touchToOpen() {
      this.$refs.modal.style.display = "flex";
      setTimeout(() => {
        this.getItems();
        this.isOpen = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.graphs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 760px;
  background: #ddd;
  border-radius: 91px;
  cursor: default;
  transform: translateX(0);
  transition: 0.4s;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 20px;

  &.loading {
    overflow-y: hidden;
  }

  &__btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__data {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      width: 100%;
    }
  }

  &__close {
    display: flex;
    justify-content: center;
  }

  h4 {
    padding: 20px 0;
  }

  button {
    margin: 5px;
    padding: 6px 22px;
    background-color: #202020;
    color: #fff;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    border: 3px solid #202020;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  .loader {
    position: fixed;
    display: flex;
    right: 0;
    top: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #ddd;
    z-index: 101;
    transform: translateX(0);
    transition: transform 0.2s ease-out;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
      position: absolute;
    }

    &.hidden {
      transform: translateX(100%);
    }

    &__indicator {
      display: flex;
      transition: 0.3s;

      div {
        transition: 0.3s;
        width: 30px;
        height: 90px;
        background: #202020;

        &.one {
          transform-origin: center;
          transform: translateX(100%) rotate(45deg);
        }
        &.two {
          background: #ddd;
        }
        &.three {
          transform-origin: center;
          transform: translateX(-100%) rotate(-45deg);
        }
      }

      &.loading {
        div {
          margin: 10px;
          width: 50px;
          height: 50px;
          background-color: #202020;
          border-radius: 50%;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
          transform: translateX(0);

          &.one {
            animation-name: load-one;
          }

          @keyframes load-one {
            30% {
              transform: translateY(-50%);
            }
          }

          &.two {
            animation-name: load-two;
          }

          @keyframes load-two {
            50% {
              transform: translateY(-50%);
            }
          }

          &.three {
            animation-name: load-three;
          }

          @keyframes load-three {
            70% {
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    &__error {
      color: #202020;
      text-align: center;
      font-size: 18px;
      margin-top: 50px;
      transition: 0.3s;
      opacity: 0;

      &.error {
        opacity: 1;
      }
    }

    > button {
      position: absolute;
      bottom: 40px;
    }

    .btn-group {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      top: 40px;
    }
  }
}

.modal-wrapper {
  z-index: 5;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;

  @media (max-width: 750px) {
    position: sticky;
    margin: 0 -20px;
  }

  &.hidden {
    background: rgba(0, 0, 0, 0);

    .graphs {
      transform: translateX(100%);
    }
  }
}
</style>
