<template>
  <div
    class="modal-wrapper"
    style="display: none"
    @click.stop="close()"
    ref="modal"
    :class="{ hidden: !isOpen }"
  >
    <div class="map-wrapper" @click.stop>
      <div class="map" ref="map"></div>
      <button @click="close">CLOSE</button>
      <p v-if="!loading">24 hours data</p>
      <div class="loader" :class="{ hidden: !loading }">
        <div class="loader__indicator" :class="{ loading: !error }">
          <div class="one"></div>
          <div class="two"></div>
          <div class="three"></div>
        </div>
        <div class="loader__error" :class="{ error }" @click.stop>
          {{ errMessage }}
        </div>
        <button @click.stop="close()">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
/* global Microsoft */

import { ActionConstructor } from "@egalteam/framework/compile/index";

export default {
  name: "CardMap",
  props: ["touchToOpen", "deviceId"],
  data() {
    return {
      isOpen: false,
      actionConstructor: null,
      error: false,
      errMessage: "",
      loading: true,
    };
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    getItems() {
      /*let filter = [[ ["device_id", "eq", this.deviceId] ]]; */
      let filter = [[ ["device_id", "eq", this.deviceId], "and", ["latitude", "gt", "0"] ]];


      this.actionConstructor
        .getItems("core", "IndicatorsMapsDayView")
        .filter(filter)
        .setPagination(100)
        .call()
        .then((data) => {
          console.log(data);
          if (data.items.length > 1) {

            let coords = data.items.map((el) => {
              return new Microsoft.Maps.Location(el.latitude, el.longitude);
            });

            let polyline = new Microsoft.Maps.Polyline(coords, null);

            let bounds = Microsoft.Maps.LocationRect.fromLocations(
              polyline.getLocations()
            );

            this.map.entities.push(polyline);

            setTimeout(() => {
              console.log(bounds);
              this.map.setView({
                bounds,
                mapTypeId: Microsoft.Maps.MapTypeId.canvasLight,
              });
              this.loading = false;
            }, 500);
          } else {
            throw new Error("Not enough data. Try again later.");
          }
        })
        .catch((err) => {
          this.error = true;
          this.errMessage = err.message;
console.log(err.message);

        });

      this.renderTouch = {};
    },
  },
  mounted() {
    this.actionConstructor = new ActionConstructor(
      process.env.VUE_APP_BASE_URL
    );

    let modal = this.$refs.modal;
    modal.addEventListener("transitionend", () => {
      if (!this.isOpen) {
        modal.style.display = "none";
      }
    });

    this.map = new Microsoft.Maps.Map(this.$refs.map, {
      showScalebar: false,
      showZoomButtons: false,
      showTermsLink: false,
      showMapTypeSelector: false,
      showLocateMeButton: false,
    });
  },
  watch: {
    touchToOpen() {
      if (this.loading || this.error) {
        this.error = false;
        this.getItems();
      }
      this.$refs.modal.style.display = "flex";
      setTimeout(() => {
        this.isOpen = true;
      });
    },
    deviceId() {
      this.loading = true;
      this.error = false;
    },
  },
};
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 100%;
}

.map-wrapper {
  position: relative;
  width: 100%;
  max-width: 760px;
  height: 100%;
  border-radius: 91px;
  transition: 0.4s;
  overflow: hidden;
  transform: translateX(0);

  p {
    position: absolute;
    padding: 10px;
    background: #fff;
    border: 3px solid #202020;
    border-radius: 10px;
    display: block;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
  }

  button {
    position: absolute;
    left: 50%;
    bottom: 57px;
    transform: translateX(-50%);
    padding: 6px 22px;
    background-color: #202020;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    border: 3px solid #202020;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  .loader {
    position: fixed;
    display: flex;
    right: 0;
    top: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #ddd;
    z-index: 101;
    transform: translateX(0);
    transition: transform 0.2s ease-out;
    align-items: center;
    justify-content: center;

    &.hidden {
      transform: translateX(100%);
    }

    &__indicator {
      display: flex;
      transition: 0.3s;

      div {
        transition: 0.3s;
        width: 30px;
        height: 90px;
        background: #202020;

        &.one {
          transform-origin: center;
          transform: translateX(100%) rotate(45deg);
        }
        &.two {
          background: #ddd;
        }
        &.three {
          transform-origin: center;
          transform: translateX(-100%) rotate(-45deg);
        }
      }

      &.loading {
        div {
          margin: 10px;
          width: 50px;
          height: 50px;
          background-color: #202020;
          border-radius: 50%;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
          transform: translateX(0);

          &.one {
            animation-name: load-one;
          }

          @keyframes load-one {
            30% {
              transform: translateY(-50%);
            }
          }

          &.two {
            animation-name: load-two;
          }

          @keyframes load-two {
            50% {
              transform: translateY(-50%);
            }
          }

          &.three {
            animation-name: load-three;
          }

          @keyframes load-three {
            70% {
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    &__error {
      color: #202020;
      text-align: center;
      font-size: 18px;
      margin-top: 50px;
      transition: 0.3s;
      opacity: 0;

      &.error {
        opacity: 1;
      }
    }
  }
}

.modal-wrapper {
  z-index: 5;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: 0.4s;

  @media (max-width: 750px) {
    position: sticky;
    margin: 0 -20px;
  }

  &.hidden {
    background: rgba(0, 0, 0, 0);

    .map-wrapper {
      transform: translateX(100%);
    }
  }
}
</style>
