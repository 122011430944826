<template>
  <div class="card" v-if="selected">
    <CardGraphs :touchToOpen="openGraphs" :device-id="selected.id" />
    <CardMap :touchToOpen="openMaps" :device-id="selected.id" />
    <div class="card__topbar">
      <div class="card__topbar-charge">
        <div class="icon">
          <img
            :src="
              require('../../assets/imgs/icons/battery-' +
                (Math.round(selected.charge / 20) > 0
                  ? Math.round(selected.charge / 20)
                  : 1) +
                '.svg')
            "
            alt=""
          />
        </div>
        {{ selected.charge }} %
      </div>
      <div class="card__topbar-isactive">
        {{ selected.online ? "Active" : "Not active" }}
      </div>
      <div class="card__topbar-id">ID {{ selected.id }}</div>
    </div>
    <div class="card__state">
      <h3>CONDITION</h3>
      <table>
        <tr>
          <td>Temperature</td>
          <td>{{ selected.temp }}</td>
        </tr>
        <tr>
          <td>Pulse</td>
          <td>{{ selected.pulse }}</td>
        </tr>
        <tr>
          <td>Saturation (SpO2)</td>
          <td>{{ selected.spO2 }}</td>
        </tr>
        <tr>
          <td>Heart Rate Variability</td>
          <td>{{ selected.vsr }}</td>
        </tr>
      </table>
      <button @click.stop="openGraphs = {}">LOG</button>
    </div>
    <div class="card__gps">
      <h3>POSITION</h3>
      <table>
        <tr>
          <td>Latitude</td>
          <td>{{ selected.latitude | latitude }}</td>
        </tr>
        <tr>
          <td>Longitude</td>
          <td>{{ selected.longitude | longitude }}</td>
        </tr>
        <tr>
          <td>Timestamp</td>
        </tr>
        <tr>
          <td>
            <div class="date">
              <p>{{ selected.day }}</p>
              <p>{{ selected.month | month }}</p>
              <p>{{ selected.year }}</p>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="date">
              <p>{{ selected.hour }}:</p>
              <p>{{ selected.minute }}:</p>
              <p>{{ selected.second }}</p>
            </div>
          </td>
        </tr>
      </table>
      <button @click.stop="openMaps = {}">MAP</button>
    </div>
    <div class="card__footer">
      <table>
        <tr>
          <td>Notifications</td>
          <td>{{ selected.notifications ? "Alarm!" : "-" }}</td>
        </tr>
        <tr>
          <td>Steps count since charge</td>
          <td>{{ selected.steps }}</td>
        </tr>
        <tr>
          <td>Hits count since charge</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Falls count since charge</td>
          <td>-</td>
        </tr>
      </table>
    </div>
    <div class="card__close">
      <button @click.stop="$emit('unselect')">CLOSE</button>
    </div>
  </div>
</template>

<script>
import CardGraphs from "./CardGraphs";
import CardMap from "./CardMap";
export default {
  name: "Card",
  components: { CardMap, CardGraphs },
  data() {
    return {
      openGraphs: {},
      openMaps: {},
    };
  },
  props: ["selected"],
  filters: {
    month(value) {
      if (value > 0) {
        let d = new Date(1, value-1, 1, 0, 0, 0, 0);
        
        const format = new Intl.DateTimeFormat("en", {
          month: "long",
        });
        return format.format(d);
      } else {
        return "-";
      }
    },
    latitude(value) {
      if (typeof value !== "number") {
        return value;
      } else {
        let absValue = Math.abs(value);
        let deg = Math.floor(absValue);
        let accMinutes = (absValue - deg) * 60;
        let minutes = Math.floor(accMinutes);
        let seconds = Math.floor((accMinutes - minutes) * 60);

        return `${deg}° ${minutes}' ${seconds}" ${value > 0 ? "N" : "S"}`;
      }
    },
    longitude(value) {
      if (typeof value !== "number") {
        return value;
      } else {
        let absValue = Math.abs(value);
        let deg = Math.floor(absValue);
        let accMinutes = (absValue - deg) * 60;
        let minutes = Math.floor(accMinutes);
        let seconds = Math.floor((accMinutes - minutes) * 60);

        return `${deg}° ${minutes}' ${seconds}" ${value > 0 ? "E" : "W"}`;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
  align-items: stretch;
  min-height: 80vh;
  padding: 0 20px;

  button {
    padding: 6px 22px;
    margin: 30px 0 50px 0;
    background-color: #202020;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    border: 3px solid #202020;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  &__topbar {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: auto;
    padding-top: 60px;

    > * {
      padding: 10px 32px;

      @media (max-width: 750px) {
        padding: 10px 15px;
      }
    }
  }

  &__topbar-id {
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &__topbar-charge {
    display: flex;
    align-items: center;
    position: relative;

    .icon {
      display: inline-block;
      margin-right: 10px;
      height: 1rem;
      display: flex;
      align-items: center;
      transform: translateY(-2px);

      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  &__state,
  &__gps {
    width: 100%;
    max-width: 320px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;

    h3 {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
    }

    table {
      width: 100%;
      margin-bottom: auto;

      td {
        padding-bottom: 20px;

        .date {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }

        &:last-child {
          text-align: right;

          &:first-child {
            text-align: left;
          }
        }
      }

      tr:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    max-width: 600px;
    display: flex;
    margin-bottom: auto;
    justify-content: stretch;

    table {
      width: 100%;

      td {
        padding-bottom: 20px;

        &:last-child {
          padding-left: 20px;
        }
      }

      tr:last-child {
        td {
          padding-bottom: 0;
        }
      }
    }
  }

  &__close {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
