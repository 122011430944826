var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-container"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"hideline header"},[_vm._v("Online")]),_c('div',{staticClass:"hideline header"},[_vm._v("ID")]),_c('div',{staticClass:"hideline header"},[_c('svg',{attrs:{"width":"20px","height":"20px"}},[_c('image',{attrs:{"xlink:href":require('../../assets/imgs/icons/pulse.svg')}})]),_vm._v("Pulse ")]),_c('div',{staticClass:"hideline header"},[_c('svg',{attrs:{"width":"20px","height":"20px"}},[_c('image',{attrs:{"xlink:href":require('../../assets/imgs/icons/spo2.svg')}})]),_vm._v("SpO2 ")]),_c('div',{staticClass:"hideline header"},[_c('svg',{attrs:{"width":"20px","height":"20px"}},[_c('image',{attrs:{"xlink:href":require('../../assets/imgs/icons/temp.svg')}})]),_vm._v("t,C ")]),_c('div',{staticClass:"hideline header"},[_c('svg',{attrs:{"width":"20px","height":"20px"}},[_c('image',{attrs:{"xlink:href":require('../../assets/imgs/icons/steps.svg')}})]),_vm._v("Steps ")]),_c('div',{staticClass:"hideline header"},[_c('svg',{attrs:{"width":"20px","height":"20px"}},[_c('image',{attrs:{"xlink:href":require('../../assets/imgs/icons/notifications.svg')}})]),_vm._v("Notifications ")]),_vm._l((_vm.sortedRows),function(row,n){return [_c('div',{key:n + 'online',staticClass:"online-indicator",class:{
          online: row.online,
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v(_vm._s(row.online ? "Active" : "Not active"))])]),_c('div',{key:n + 'id',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("ID:")]),_vm._v(" "+_vm._s(row.id)+" ")]),_c('div',{key:n + 'pulse',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("Pulse")]),_c('span',[_vm._v(_vm._s(row.pulse)+" pm")])]),_c('div',{key:n + 'spo2',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("SpO2")]),_c('span',[_vm._v(_vm._s(row.spO2)+" %")])]),_c('div',{key:n + 'temp',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("Temperature")]),_c('span',[_vm._v(_vm._s(row.temp)+" C")])]),_c('div',{key:n + 'steps',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("Steps")]),_c('span',[_vm._v(_vm._s(row.steps))])]),_c('div',{key:n + 'notifications',class:{
          hideline: n === 0 || _vm.sortedRows[n - 1].rowIsSelected,
          selected: row.rowIsSelected,
        },on:{"click":function($event){return _vm.selectRow(row)}}},[_c('span',[_vm._v("Notifications")]),_c('span',[_vm._v(_vm._s(row.notifications ? "Alarm!" : "-"))])])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }